// Primary colours
export const MONKEY_ASH_GREY = '#BDBDBD'
export const MONKEY_BLACK = '#303030'
export const MONKEY_DARK_GREY = '#454444'
export const MONKEY_DARK_NIGHT = '#084965'
export const MONKEY_GORILLA_GREY = '#757575'
export const MONKEY_NIGHT = '#155B7C'
export const MONKEY_OFF_WHITE = '#F2F2F2'
export const MONKEY_WHITE = '#FFFFFF'
export const MONKEY_OLD_ORANGE_OETANG = '#B85B00'
export const MONKEY_ORANGE_OETANG = '#FF8A17'
export const MONKEY_TAUPE_GREY = '#E0E0E0'
export const MONKEY_SKY = '#E3EEF2'
export const MONKEY_SPRING_SKY = '#EBF7F9'

// Secondary colours
export const MONKEY_BANANA_YELLOW = '#F2C94C'
export const MONKEY_BROWN = '#8e6327'
export const MONKEY_LIGHT_BROWN = '#e2dbc9'
export const MONKEY_FAINT = '#fbf1d8'
export const MONKEY_CHERRY = '#AB001F'
export const MONKEY_GRASS_DARK = '#254000'
export const MONKEY_GRASS = '#4C8400'
export const MONKEY_GRASS_LIGHT = '#93C456'
export const MONKEY_NAKED_1 = '#FCCC9C'
export const MONKEY_NAKED_2 = '#F4DBB6'
export const MONKEY_NOSE = '#ED8484'
export const MONKEY_OLIVE = '#ACD386'
export const MONKEY_TREES = '#81AC47'
export const MONKEY_WATER = '#28B0BE'
export const MONKEY_ROOF_COCOA = '#A47062'
export const MONKEY_ROOF_BLACK = '#5A5C5E'
export const MONKEY_ROOF_GREY = '#91938E'
export const MONKEY_ROOF_ASH = '#B9BFBF'

// Rebrand colours
export const MONKEY_PLUM = '#391636'
export const MONKEY_PLUM_BRIGHT = '#64275F'
export const MONKEY_PLUM_BRIGHTEST = '#995892'
export const MONKEY_LAVENDER_BLUSH = '#E8DEE7'
export const MONKEY_LAVENDER_FAINT = '#F8F2F8'
export const MONKEY_ORANGE_BRIGHT = '#FF4B00'
export const MONKEY_ORANGE_DARK = '#D74100'
export const MONKEY_WARM_LIGHT_FAINT = '#FAF2EC'

export const COLOURS = {
    PRIMARY: {
        MONKEY_ASH_GREY,
        MONKEY_BLACK,
        MONKEY_DARK_GREY,
        MONKEY_DARK_NIGHT,
        MONKEY_GORILLA_GREY,
        MONKEY_NIGHT,
        MONKEY_WHITE,
        MONKEY_OFF_WHITE,
        MONKEY_OLD_ORANGE_OETANG,
        MONKEY_ORANGE_OETANG,
        MONKEY_TAUPE_GREY,
        MONKEY_SKY,
        MONKEY_SPRING_SKY,
        MONKEY_PLUM,
        MONKEY_PLUM_BRIGHT,
        MONKEY_PLUM_BRIGHTEST,
        MONKEY_LAVENDER_BLUSH,
        MONKEY_LAVENDER_FAINT,
        MONKEY_ORANGE_BRIGHT,
        MONKEY_ORANGE_DARK,
        MONKEY_WARM_LIGHT_FAINT
    },
    SECONDARY: {
        MONKEY_BANANA_YELLOW,
        MONKEY_BROWN,
        MONKEY_LIGHT_BROWN,
        MONKEY_FAINT,
        MONKEY_CHERRY,
        MONKEY_GRASS,
        MONKEY_GRASS_LIGHT,
        MONKEY_NAKED_1,
        MONKEY_NAKED_2,
        MONKEY_NOSE,
        MONKEY_OLIVE,
        MONKEY_TREES,
        MONKEY_WATER,
        MONKEY_ROOF_COCOA,
        MONKEY_ROOF_BLACK,
        MONKEY_ROOF_GREY,
        MONKEY_ROOF_ASH
    }
}

export default COLOURS
